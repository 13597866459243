import React, { useEffect } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { Box, Tab, Tabs } from '@mui/material';
import { Colors, UserStatus } from '../../enums/enums';
import HomeCoaching from './HomeCoaching';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeMentoring from './HomeMentoring';
import { findInternalUser, findUserProfile, getUserProfile } from '../../redux/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import HomeInactive from './HomeInactive';

interface Props {}
const WelcomePage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('LOCELLE:USER');
  const user = useSelector(getUserProfile);
  const [value, setValue] = React.useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userId) {
      dispatch(findUserProfile(userId));
    }
  }, [userId]);

  return (
    <>
      {UserStatus.Active === user?.status && (
        <>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="inherit"
              variant="standard"
              sx={{
                '& .MuiTabs-indicator': { backgroundColor: Colors.MenuText },
                '& .MuiTab-root': { color: 'grey', width: '250px' },
                '& .Mui-selected': { color: Colors.Text }
              }}
            >
              <Tab sx={{ mt: 2 }} value={1} label="Coaching" icon={<HomeOutlinedIcon />} />
              <Tab sx={{ mt: 2 }} value={2} label="Mentorship" icon={<HomeOutlinedIcon />} />
            </Tabs>
          </Box>

          {value === 1 && (
            <Box sx={{ mt: 4 }}>
              <HomeCoaching />
            </Box>
          )}
          {value === 2 && (
            <Box sx={{ mt: 4 }}>
              <HomeMentoring />
            </Box>
          )}
        </>
      )}

      {UserStatus.Inactive === user?.status && (
        <Box sx={{ mt: 6 }}>
          <HomeInactive />
        </Box>
      )}
    </>
  );
};

export default WelcomePage;
