import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useDispatch, useSelector } from 'react-redux';
import CancelCoachingSessionDialog from './CancelCoachingSessionDialog';
import { format, parseISO } from 'date-fns';
import { CoachingSessionStatus } from './coaching.enum';
import { completedSessionStatuses, currentSessionStatuses } from './coaching.constant';
import {
  findCoachingSessionsByUserId,
  getCoachingSessions,
  markCompleteCoachingSession
} from '../../redux/actions/coachingSessions';
import { Colors, NoteTypes, SessionStageType } from '../../enums/enums';
import CheckIcon from '@mui/icons-material/Check';
import CoachingMeetingRoomDialog from './CoachingMeetingRoomDialog';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import { ICoachingSessionFullDTO } from './CoachingSessionFullDTO';
import CoachingNoteEditor from './CoachingNoteEditor';
import { ConfirmationDialog } from '../../components/dialogs/ConfirmationDialog';
import { fetchContentFiles, getFiles } from '../../redux/actions/content';
import { Tab, Tabs } from '@material-ui/core';
import { displaySessionDuration, getHoursLeftForTheDay } from '../../utils/basic.utils';
import GroupParticipantsDetails from './GroupParticipantsDetails';
import TransitEnterexitSharpIcon from '@mui/icons-material/TransitEnterexitSharp';
import GroupIcon from '@mui/icons-material/Group';

enum SessionsTabType {
  Current,
  Completed
}

const MeetingRoomDialogTransition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

export default function NewCommonSessions() {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('LOCELLE:USER');
  const uploadedFiles = useSelector(getFiles);
  const [openNoteEditor, setOpenNoteEditor] = useState(false);
  const [currentSession, setCurrentSession] = useState<ICoachingSessionFullDTO>(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(null);
  const [tabValue, setTabValue] = useState<SessionsTabType>(SessionsTabType.Current);
  const [showMeetingRoom, setShowMeetingRoom] = useState<boolean>(false);
  const [completeSessionDialog, setCompleteSessionDialog] = React.useState({ id: null, date: null });
  const allSessions = useSelector(getCoachingSessions);
  const [openGroupDetailsDialog, setOpenGroupDetailsDialog] = useState(false);
  const [participants, setParticipants] = useState([]);
  const sessions = allSessions?.filter((s) =>
    s.stage === SessionStageType.Individual ? s.participantId === userId || s.groupDto.leader.id === userId : true
  );

  sessions?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  useEffect(() => {
    refreshSessionList();
    dispatch(fetchContentFiles());
  }, []);

  const handleClose = (): void => {
    setOpenCancelDialog(false);
    setOpenNoteEditor(false);
    refreshSessionList();
    setCurrentSession(null);
    setShowMeetingRoom(false);
    setOpenGroupDetailsDialog(false);
    setParticipants([]);
  };

  const refreshSessionList = () => {
    dispatch(findCoachingSessionsByUserId(userId));
  };

  const tableRows = (
    sessionStatuses: CoachingSessionStatus[],
    isUpcoming: boolean,
    isPassed?: boolean,
    isToday?: boolean,
    isNext?: boolean
  ) => {
    return sessions
      .filter((coachingSession) => sessionStatuses?.includes(coachingSession.status))
      .filter((coachingSession) => !!coachingSession.isUpcoming === isUpcoming)
      .filter((coachingSession) => !!coachingSession.isToday === isToday)
      .filter((coachingSession) => !!coachingSession.isNext === isNext)
      .filter((coachingSession) =>
        isPassed
          ? new Date(new Date(coachingSession.date).getTime() + getHoursLeftForTheDay() * 60 * 60 * 1000) < new Date()
          : new Date(new Date(coachingSession.date).getTime() + getHoursLeftForTheDay() * 60 * 60 * 1000) > new Date()
      )
      .map((coachingSession) => (
        <TableRow hover key={coachingSession?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell sx={{ minWidth: '200px' }}>
            <Typography
              width="100%"
              sx={{ fontWeight: 'bold', color: Colors.TextElevated, ml: 2, fontSize: '1.05rem' }}
            >
              {coachingSession?.topic
                ? coachingSession?.topic
                : coachingSession?.stage === SessionStageType.MeetAndGreet
                ? 'First Session'
                : coachingSession?.stage}
            </Typography>
          </TableCell>
          <TableCell sx={{ minWidth: '180px' }}>
            <Typography>{format(parseISO(coachingSession?.date), 'MMMM d, yyyy')}</Typography>
            <Typography>{format(parseISO(coachingSession?.date), 'h:mm a')}</Typography>
            <Typography sx={{ color: 'grey', fontSize: 'small' }}>
              {displaySessionDuration(coachingSession?.duration)} session
            </Typography>
          </TableCell>
          <TableCell sx={{ minWidth: '250px' }}>
            {/*  leader info*/}
            <Link
              href={coachingSession?.groupDto?.leader?.linkedin}
              target="_blank"
              rel="noopener"
              underline="none"
              sx={{ cursor: coachingSession?.groupDto?.leader?.linkedin ? 'pointer' : '', color: 'black' }}
            >
              <Typography sx={{ display: 'flex', justifyContent: 'start' }}>
                <span
                  style={{ marginRight: '3px', fontSize: '1.05rem', fontWeight: 'bold', color: Colors.TextElevated }}
                >
                  {coachingSession?.groupDto?.leader?.name}
                </span>
                <LinkedInIcon
                  fontSize="small"
                  color={!coachingSession.groupDto?.leader?.linkedin ? 'disabled' : 'primary'}
                />
              </Typography>
            </Link>
            {/*  co-leader info*/}
            {coachingSession?.groupDto?.coLeader && (
              <Typography sx={{ display: 'flex', justifyContent: 'start' }}>
                <span style={{ marginRight: '3px', color: 'grey' }}>{coachingSession?.groupDto?.coLeader?.name}</span>
                <Link
                  href={coachingSession?.groupDto?.coLeader?.linkedin}
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  sx={{ cursor: coachingSession?.groupDto?.coLeader?.linkedin ? 'pointer' : '', color: 'grey' }}
                >
                  <LinkedInIcon
                    fontSize="small"
                    color={!coachingSession?.groupDto?.coLeader?.linkedin ? 'disabled' : 'primary'}
                  />
                </Link>
              </Typography>
            )}
            {/*  participants info for not leader*/}
            {coachingSession?.groupDto?.participants?.length > 1 &&
              coachingSession?.stage !== SessionStageType.Individual &&
              coachingSession?.participantId !== userId && (
                <>
                  {coachingSession?.groupDto?.leader?.id !== userId &&
                    coachingSession?.groupDto?.coLeader?.id !== userId && (
                      <Typography sx={{ color: Colors.TextElevated }}>
                        Me,{' '}
                        <Tooltip
                          arrow
                          title={coachingSession?.groupDto?.participants
                            ?.filter((p) => p?.id != userId)
                            .map((value, index) => (
                              <React.Fragment key={index}>
                                {value?.name}
                                <br />
                              </React.Fragment>
                            ))}
                          placement="right"
                        >
                          <span style={{ color: 'grey', cursor: 'default' }}>
                            +{coachingSession?.groupDto?.participants?.length - 1}
                            {coachingSession?.groupDto?.participants?.length - 1 === 1
                              ? ' participant'
                              : ' participants'}
                            <TransitEnterexitSharpIcon color="success" fontSize="small" />
                          </span>
                        </Tooltip>
                      </Typography>
                    )}

                  {/*  participants info for leader or co-leader*/}
                  {(coachingSession?.groupDto?.leader?.id === userId ||
                    coachingSession?.groupDto?.coLeader?.id === userId) && (
                    <Typography sx={{ color: Colors.TextElevated }}>
                      <Tooltip
                        arrow
                        title={coachingSession?.groupDto?.participants
                          ?.filter((p) => p?.id != userId)
                          .map((value, index) => (
                            <React.Fragment key={index}>
                              {value?.name}
                              <br />
                            </React.Fragment>
                          ))}
                        placement="right"
                      >
                        <span style={{ color: 'grey', cursor: 'default' }}>
                          + {coachingSession?.groupDto?.participants?.length} participants
                        </span>
                      </Tooltip>
                    </Typography>
                  )}
                </>
              )}
            {coachingSession?.groupDto?.participants?.length === 1 && (
              <Typography sx={{ color: Colors.TextElevated }}>
                {coachingSession?.groupDto?.participants[0]?.id === userId && 'Me, 1:1 session'}
                {(coachingSession?.groupDto?.leader?.id === userId ||
                  coachingSession?.groupDto?.coLeader?.id === userId) &&
                  '1:1 with ' + coachingSession?.groupDto?.participants[0]?.name}
              </Typography>
            )}
            {coachingSession?.stage === SessionStageType.Individual && coachingSession?.participantId === userId && (
              <Typography sx={{ color: 'green' }}>Individual Session</Typography>
            )}
            {coachingSession?.stage === SessionStageType.Individual &&
              coachingSession?.participantId !== userId &&
              (coachingSession?.groupDto?.leader.id === userId ||
                coachingSession?.groupDto?.coLeader.id === userId) && (
                <Typography sx={{ color: 'green' }}>
                  {' '}
                  1:1 with{' '}
                  {
                    coachingSession?.groupDto?.participants.find(
                      (participant) => participant.id === coachingSession?.participantId
                    )?.name
                  }
                </Typography>
              )}

            {/*  Group info for leader or co-leader*/}
            {(coachingSession?.groupDto?.leader?.id === userId ||
              coachingSession?.groupDto?.coLeader?.id === userId) && (
              <>
                <Button
                  variant="outlined"
                  color="success"
                  endIcon={<GroupIcon />}
                  sx={{ height: '25px', mt: 0.5, py: 1.8 }}
                  onClick={() => {
                    setOpenGroupDetailsDialog(true);
                    if (coachingSession?.stage !== SessionStageType.Individual) {
                      setParticipants(coachingSession?.groupDto?.participants);
                    }
                    if (coachingSession?.stage === SessionStageType.Individual) {
                      const participant = coachingSession?.groupDto?.participants.find(
                        (participant) => participant.id === coachingSession?.participantId
                      );
                      setParticipants([participant]);
                    }
                  }}
                >
                  View Info
                </Button>
              </>
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography>
              <Chip
                sx={
                  coachingSession?.status === CoachingSessionStatus.Confirmed
                    ? {
                        backgroundColor: Colors.SelectedDrawerBackground
                      }
                    : {}
                }
                label={coachingSession?.status}
              />
            </Typography>
          </TableCell>

          <TableCell>
            {coachingSession?.linkedFileId && (
              <Button
                variant="outlined"
                color="success"
                sx={{
                  textTransform: 'none',
                  width: '130px',
                  height: '40px'
                }}
                href={uploadedFiles?.find((file) => file?.id === coachingSession?.linkedFileId)?.azureUrl}
                target="_blank"
              >
                View Content
              </Button>
            )}
          </TableCell>

          <TableCell>
            {SessionsTabType.Current === tabValue && (
              <Button
                variant="contained"
                color="success"
                sx={{
                  width: '80px',
                  height: '40px',
                  backgroundColor: Colors.ButtonGreen
                }}
                onClick={() => {
                  setCurrentSession(coachingSession);
                  setShowMeetingRoom(true);
                }}
              >
                Join
              </Button>
            )}
          </TableCell>

          <TableCell>
            <Button
              variant="outlined"
              color="success"
              sx={{
                textTransform: 'none',
                width: '130px',
                height: '40px'
              }}
              onClick={() => {
                setCurrentSession(coachingSession);
                setOpenNoteEditor(true);
              }}
            >
              View Notes
            </Button>
          </TableCell>

          <TableCell>
            {SessionsTabType.Current === tabValue &&
              (coachingSession?.groupDto?.leader?.id === userId ||
                coachingSession?.groupDto?.coLeader?.id === userId) && (
                <Button
                  variant="outlined"
                  color="success"
                  // Allow to mark sessions complete not earlier than 15 mins after sessions start.
                  disabled={new Date() < new Date(new Date(coachingSession?.date).getTime() + 15 * 60000)}
                  startIcon={<CheckIcon />}
                  sx={{
                    textTransform: 'none',
                    width: '160px',
                    '&:hover': {
                      backgroundColor: Colors.ButtonGreen,
                      color: 'white'
                    }
                  }}
                  onClick={() => {
                    setCompleteSessionDialog({ id: coachingSession?.id, date: coachingSession?.date });
                    // refreshSessionList();
                  }}
                >
                  Mark Complete
                </Button>
              )}
          </TableCell>
          <TableCell>
            {SessionsTabType.Current === tabValue &&
              (coachingSession?.groupDto?.leader?.id === userId ||
                coachingSession?.groupDto?.coLeader?.id === userId) && (
                <Button variant="outlined" color="primary" onClick={() => setOpenCancelDialog(coachingSession?.id)}>
                  Cancel
                </Button>
              )}
          </TableCell>
        </TableRow>
      ));
  };

  const upcomingSessionsElements = tableRows(currentSessionStatuses, true, false, false, false);
  const todaysSessionsElements = tableRows(currentSessionStatuses, false, false, true, false);
  const passedSessionElements = tableRows(currentSessionStatuses, false, true, false, false);
  const nextSessionElements = tableRows(currentSessionStatuses, true, false, false, true);
  const completedSessionElements = tableRows(completedSessionStatuses, false, null, false, false);

  return (
    <Box sx={{ ml: 3 }}>
      <Dialog maxWidth="md" open={openNoteEditor} onClose={handleClose}>
        <DialogContent sx={{ p: 1, pt: 0, height: '900px', backgroundColor: Colors.BackgroundMain }}>
          <CoachingNoteEditor
            sessionId={currentSession?.id}
            stage={currentSession?.stage}
            onClose={handleClose}
            noteType={NoteTypes.Coaching}
            userId={userId}
          />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen open={showMeetingRoom} onClose={handleClose} TransitionComponent={MeetingRoomDialogTransition}>
        {showMeetingRoom && currentSession && (
          <CoachingMeetingRoomDialog
            roomOrganizerName={currentSession?.groupDto?.leader?.name}
            sessionId={currentSession?.id}
            stage={currentSession?.stage}
            sessionTopic={currentSession?.topic}
            participants={currentSession?.groupDto?.participants}
            onConfirmedClose={handleClose}
          ></CoachingMeetingRoomDialog>
        )}
      </Dialog>

      <Tabs
        value={tabValue}
        onChange={(event: ChangeEvent<{}>, newValue: SessionsTabType) => setTabValue(newValue)}
        indicatorColor="primary"
        TabIndicatorProps={{ style: { background: Colors.TextElevated } }}
        style={{ marginBottom: 20, color: Colors.Text }}
      >
        <Tab label="My Sessions" value={SessionsTabType.Current} />
        <Tab
          disabled={sessions.filter((s) => s.status === CoachingSessionStatus.Completed).length === 0}
          label="Completed Sessions"
          value={SessionsTabType.Completed}
        />
      </Tabs>

      {tabValue === SessionsTabType.Completed && !!completedSessionElements?.length && (
        <TableContainer component={Paper} sx={{ mt: 6 }}>
          <Table sx={{ minWidth: 650, backgroundColor: Colors.BackgroundMain }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: Colors.BackgroundDrawer }}>
              <TableRow hover>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Session</TableCell>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>When</TableCell>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Who</TableCell>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Status</TableCell>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}></TableCell>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }} align="right">
                  Actions
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{SessionsTabType.Completed === tabValue && completedSessionElements}</TableBody>
          </Table>
        </TableContainer>
      )}

      {tabValue === SessionsTabType.Current && !!todaysSessionsElements?.length && (
        <>
          <Typography sx={{ mb: 2, fontSize: '1.3rem', color: Colors.TextElevated }}>Today's Sessions</Typography>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table sx={{ minWidth: 650, backgroundColor: Colors.BackgroundMain }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.BackgroundDrawer }}>
                <TableRow hover>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Session</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>When</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Who</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Status</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}></TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }} align="right">
                    Actions
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {sessions.length > 0 && (
                <TableBody>{SessionsTabType.Current === tabValue && todaysSessionsElements}</TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}

      {tabValue === SessionsTabType.Current && !!nextSessionElements?.length && (
        <>
          <Typography sx={{ mb: 2, fontSize: '1.3rem', color: Colors.TextElevated }}>Next Session</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, backgroundColor: Colors.BackgroundMain }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.BackgroundDrawer }}>
                <TableRow hover>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Session</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>When</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Who</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Status</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}></TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }} align="right">
                    Actions
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {sessions.length > 0 && (
                <TableBody>{SessionsTabType.Current === tabValue && nextSessionElements}</TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}

      {SessionsTabType.Current === tabValue && !!sessions?.length && (
        <>
          <Typography sx={{ mb: 2, mt: 3, fontSize: '1.3rem', color: Colors.TextElevated }}>
            Upcoming Sessions
          </Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, backgroundColor: Colors.BackgroundMain }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.BackgroundDrawer }}>
                <TableRow hover>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Session</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>When</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Who</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Status</TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }} align="right">
                    Actions
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SessionsTabType.Current === tabValue && upcomingSessionsElements}
                {!upcomingSessionsElements?.length && SessionsTabType.Current === tabValue && (
                  <Typography sx={{ m: 4, fontSize: '16px' }}>You don't have any upcoming sessions</Typography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {SessionsTabType.Current === tabValue && sessions?.length === 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, backgroundColor: Colors.BackgroundMain }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.BackgroundDrawer }}>
                <TableRow hover>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Session</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>When</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Who</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Status</TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }} align="right">
                    Actions
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!upcomingSessionsElements?.length && SessionsTabType.Current === tabValue && (
                  <Typography sx={{ mb: 2, my: 6, ml: 3, fontSize: '1.3rem', color: Colors.TextElevated }}>
                    Looks like you don't have any sessions scheduled yet. We're working on it!
                  </Typography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {tabValue === SessionsTabType.Current && !!passedSessionElements?.length && (
        <>
          <Typography sx={{ mb: 2, mt: 3, fontSize: '1.3rem', color: Colors.TextElevated }}>Passed Sessions</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, backgroundColor: Colors.BackgroundMain }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.BackgroundDrawer }}>
                <TableRow hover>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Session</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>When</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Who</TableCell>
                  <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: Colors.TextTabs }}>Status</TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: Colors.TextTabs }} align="right">
                    Actions
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {sessions?.length > 0 && (
                <TableBody>{SessionsTabType.Current === tabValue && passedSessionElements}</TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}

      <CancelCoachingSessionDialog
        isOpen={openCancelDialog}
        handleClose={handleClose}
        userId={userId}
        refreshSessionList={refreshSessionList}
      />
      <ConfirmationDialog
        isOpen={!!completeSessionDialog?.id}
        title="Complete Session?"
        message="Are you sure you want to mark this session complete? It will be moved to COMPLETED section for all participants."
        onConfirmBtn={() => {
          dispatch(
            markCompleteCoachingSession(
              completeSessionDialog?.id,
              CoachingSessionStatus.Completed,
              completeSessionDialog.date
            )
          );
          setCompleteSessionDialog({ id: null, date: null });
        }}
        onCancelBtn={() => setCompleteSessionDialog({ id: null, date: null })}
      />

      <GroupParticipantsDetails isOpen={openGroupDetailsDialog} handleClose={handleClose} participants={participants} />
    </Box>
  );
}