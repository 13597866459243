import React, { useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import StarsIcon from '@mui/icons-material/Stars';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import GradingIcon from '@mui/icons-material/Grading';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import LogoIcon from '../../assets/locelle-icon.png';
import { makeStyles } from '@material-ui/core';
import VideoDialog from '../Learning/VideoDialog';
import { Colors } from '../../enums/enums';

const HomeCoaching = () => {
  const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
  const useStyles = makeStyles({
    root: {
      width: '430px',
      placeSelf: 'center',
      height: '320px',
      marginTop: '20px',
      display: 'flex',
      alignItems: 'baseline',
      alignContent: 'center',
      flexWrap: 'wrap',
      transition: 'transform 0.15s ease-in-out',
      '&:hover': { transform: 'scale3d(1.05, 1.05, 1)', cursor: 'pointer' }
    }
  });
  const [data, setData] = useState({ items: [] });
  const [open, setOpen] = useState(false);
  const [videoId, setVideoId] = useState('');

  {
    /* Temporarily disable fetching video content until new playlist created*/
  }
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${YOUTUBE_PLAYLIST_ITEMS_API}?playlistId=PLCvABU_CjrRDWBL5SUUvSc20-DYTh9bPD&part=snippet,id&maxResults=40&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
  //     )
  //     .then((res) => {
  //       setData(res.data);
  //       console.log(data);
  //     });
  // }, []);

  return (
    <>
      <VideoDialog open={open} setOpen={setOpen} videoId={videoId} />
      <Stack direction="column" spacing={2} justifyContent="center">
        <Typography variant="h4" sx={{ textAlign: 'center', color: Colors.TextElevated }}>
          Coaching Timeline
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '1.1rem', color: Colors.Text }}>
          We’re so happy you are here! Below is an overview of our process and key items. Let’s get started!
        </Typography>
        {/* Temporarily hide video content*/}
        {/*<Card*/}
        {/*  key="1"*/}
        {/*  className={classes.root}*/}
        {/*  onClick={() => {*/}
        {/*    setVideoId(data?.items[0]?.snippet?.resourceId?.videoId);*/}
        {/*    setOpen(true);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <CardMedia*/}
        {/*    component="img"*/}
        {/*    image={*/}
        {/*      data?.items[0]?.snippet?.thumbnails?.maxres?.url ||*/}
        {/*      `https://img.youtube.com/vi/${data[0]?.snippet?.resourceId?.videoId}/maxresdefault.jpg`*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <CardContent>*/}
        {/*    <Typography variant="body2" color="text.secondary">*/}
        {/*      VIDEO*/}
        {/*    </Typography>*/}
        {/*    <Typography gutterBottom variant="h6" component="div">*/}
        {/*      {data?.items[0]?.snippet?.title}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Stack>
      <VerticalTimeline>
        <motion.div animate={{ scale: 1 }} transition={{ duration: 0.5 }}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: '#FFFFFF',
              color: 'rgb(74, 62, 62)',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
            }}
            iconStyle={{
              background: Colors.LogoRed,
              color: '#fff',
              textAlign: 'center'
            }}
            icon={<img src={LogoIcon} height="50px" width="50px" />}
            style={{ marginBottom: '90px' }}
          >
            <h2 style={{ color: Colors.TextElevated }}>Onboarding</h2>
            <p style={{ fontWeight: 100, color: Colors.Text }}>
              Complete the onboarding form - this is where you will share your goals, experience etc to pair you with
              the right coach and (group if applicable).
            </p>
          </VerticalTimelineElement>
        </motion.div>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<PeopleRoundedIcon />}
          style={{ marginTop: '-100px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>The First Group Session (aka Meet & Greet)</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            You and your group members all meet with the coach, get to know each other better and get more information
            on how the sessions will go. This session will also have light coaching to give you an insight into what
            that looks like for all your sessions.
          </p>
          <br />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<PeopleRoundedIcon />}
          style={{ marginTop: '-100px', marginBottom: '80px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Regular Sessions </h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            Sessions will take place as scheduled. Feel free to reach out to Team Locelle (team@locelle.com) at any time
            for further assistance. For group sessions, you get a total of 8-10 sessions (depending on the program) and
            1 private 1:1 session with your coach near the end of the program.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<GradingIcon />}
          style={{ marginTop: '-100px', marginBottom: '80px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Mid-Cohort Assessment</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            This part is mandatory and important to ensure you are getting the value. This also allows us to see how we
            can make the remainder of the cohort successful for your leadership journey.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<StarsIcon />}
          style={{ marginTop: '-100px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Resources</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            BestHuman offers tools and resources to support your journey. You will receive a leadership development
            template as well as meeting agenda and workspace guides to help you learn essential skills, build your own
            plan and stay accountable. Completion of this plan is essential. You will not receive a certificate of
            completion of the program if you do not have this completed.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<GradingIcon />}
          style={{ marginTop: '-100px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Final Assessment</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            BestHuman’s goal is to help you become a better people leader through peer-peer learning and coaching. This
            ultimately will help change your thoughts and behavior as you lead people effectively with peak performance.
            This assessment will help in seeing how you have evolved, what you have learned and what actions you believe
            you need to commit to to continue on your leadership journey.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#FFFFFF' }}
          icon={<VerifiedIcon />}
          style={{ marginTop: '-100px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Certificate of completion</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            Certificate of leadership program completion will be awarded if you have:
            <br /> &#x2022; <i>Attended a minimum of 80% of the sessions</i>
            <br /> &#x2022; <i>Completed evaluation + assessment</i>
            <br /> &#x2022; <i>Developed personal leadership plan</i>
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          iconStyle={{ background: Colors.LogoRed, color: '#fff' }}
          icon={<CelebrationIcon />}
          style={{ marginTop: '-50px' }}
        />
      </VerticalTimeline>
    </>
  );
};

export default HomeCoaching;
