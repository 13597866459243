import { makeStyles } from '@material-ui/core';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React, { useState } from 'react'
import VideoDialog from '../Learning/VideoDialog';

type Props = {
  item: any;
}

const VideoTimeline = ({item}: Props) => {

  const useStyles = makeStyles({
    root: {
      width: 200,
      transition: "transform 0.15s ease-in-out",
      "&:hover": { transform: "scale3d(1.05, 1.05, 1)" , cursor: 'pointer'},
    },
  });

  const [data, setData] = useState({ items: []});
  const [open, setOpen] = useState(false);
  const [videoId, setVideoId] = useState('');

  const classes = useStyles();

  return (
    <>
    <Card key={item?.id} className={classes.root} onClick={() => {setVideoId(item?.snippet.resourceId.videoId); setOpen(true)}} >
     <CardMedia component="img" image={item?.snippet.thumbnails?.maxres?.url || `http://img.youtube.com/vi/${item?.snippet.resourceId.videoId}/maxresdefault.jpg`}  /> 
      
    </Card>
    <VideoDialog open={open} setOpen={setOpen} videoId={videoId} />
    </>
  )
}

export default VideoTimeline