import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import StarsIcon from '@mui/icons-material/Stars';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EventNoteIcon from '@mui/icons-material/EventNote';
import axios from 'axios';
import VideoTimeline from './VideoTimeline';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { Backdrop, Dialog, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import { motion } from 'framer-motion';
import LogoIcon from '../../assets/locelle-icon.png';
import { Colors } from '../../enums/enums';

interface Props {}
const HomeMentoring: React.FC<Props> = () => {
  const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';

  const [data, setData] = useState({ items: [] });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${YOUTUBE_PLAYLIST_ITEMS_API}?playlistId=PLCvABU_CjrRBsVrHGKYv8aUkg_4cqhq17&part=snippet,id&maxResults=7&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
      )
      .then((res) => {
        setData(res.data);
      });
  }, []);

  return (
    <>
      <Typography variant="h4" sx={{ textAlign: 'center', color: Colors.TextElevated }}>
        Mentorship Timeline
      </Typography>
      <Typography sx={{ textAlign: 'center', mt: 2, fontSize: '1.1rem', color: Colors.Text }}>
        We’re so happy you are here! Below is an overview of your learning journey. Let’s get started!
      </Typography>
      <VerticalTimeline>
        <motion.div animate={{ scale: 1 }} transition={{ duration: 0.5 }}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: '#FFFFFF',
              color: 'rgb(74, 62, 62)',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
            }}
            iconStyle={{
              background: Colors.LogoRed,
              color: '#fff',
              textAlign: 'center'
            }}
            icon={<img src={LogoIcon} height="50px" width="50px" />}
            style={{ marginBottom: '90px' }}
          >
            <h2 style={{ color: Colors.TextElevated }}>Welcome</h2>
            <p style={{ fontWeight: 100, color: Colors.Text }}>
              We are thrilled to be part of your learning and development journey and look forward to delivering a great
              experience and help you grow personally and professionally.
            </p>
            <p style={{ fontSize: '14px', color: Colors.Text, marginBottom: '15px' }}>
              Watch this welcome video about the program and platform. (2 mins)
            </p>
            <VideoTimeline item={data?.items[0]} />
          </VerticalTimelineElement>
        </motion.div>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<PeopleRoundedIcon />}
          style={{ marginTop: '-150px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Onboarding</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            Thank you for filling out the onboarding form. This is the first step to get you started.
          </p>
          <br />

          <VideoTimeline item={data?.items[1]} />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<PersonSearchIcon sx={{ fontSize: '2rem' }} />}
          style={{ marginTop: '-100px', marginBottom: '80px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Mentor Matching </h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            Once you have completed the onboarding form we will take all that awesome information about you and use it
            to find a suitable mentor match. This process can take up to 2 weeks. In the meantime we encourage you to
            take a look at key resources under the “learning tab” so you are prepared and ready to make the most out of
            your mentorship when your first session starts.
          </p>
          <p style={{ fontSize: '14px', color: Colors.Text, marginBottom: '15px' }}>
            Watch this 60 second video on how we create our matches.{' '}
          </p>
          <VideoTimeline item={data?.items[2]} />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<EventNoteIcon />}
          style={{ marginTop: '-150px', marginBottom: '80px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Sessions</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            Sessions will be pre-booked in advance by the Locelle/BestHuman Team. Within this mentorship program you
            will get up to (2) sessions per month over the course of this 3-4 month cohort. Each session is 30 minutes
            in length.
          </p>

          <p style={{ color: Colors.Text }}>
            Next must do step:
            <ul>
              <li>Watch this 60 second video on what your sessions will entail</li>
              <li>
                Review our{' '}
                <span
                  style={{ cursor: 'pointer', color: Colors.Text, textDecoration: 'underline' }}
                  onClick={() => setOpen(true)}
                >
                  terms and conditions
                </span>{' '}
                on rescheduling, canceling sessions etc. (1 min)
              </li>
            </ul>
          </p>

          <VideoTimeline item={data?.items[3]} />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<StarsIcon />}
          style={{ marginTop: '-150px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Surveys</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            Throughout your mentorship you will have a total of (2) surveys that are required so that we can ensure you
            are on track and making the most of your mentorship, remember we want to see you grow and we are here to
            help you!
          </p>

          <p style={{ fontSize: '14px', color: Colors.Text, marginBottom: '15px' }}>
            Watch this 60 second video on our surveys and why your feedback is so valuable
          </p>
          <VideoTimeline item={data?.items[4]} />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: '#FFFFFF',
            color: 'rgb(74, 62, 62)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
          }}
          iconStyle={{ background: Colors.TextElevated, color: '#fff' }}
          icon={<RestartAltOutlinedIcon />}
          style={{ marginTop: '-100px' }}
        >
          <h2 style={{ color: Colors.TextElevated }}>Re-enroll & Work Towards a Different Goal</h2>
          <p style={{ fontWeight: 100, color: Colors.Text }}>
            It doesn’t have to stop there - we are happy to continue working on your current goal(s) or new goal(s), set
            you up with a new mentor or keep you current mentor.
          </p>

          <p style={{ fontSize: '14px', color: Colors.Text, marginBottom: '15px' }}>
            Watch this 60 second video on the next steps
          </p>
          <VideoTimeline item={data?.items[5]} />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          iconStyle={{ background: Colors.LogoRed, color: '#fff', marginTop: '-30px' }}
          icon={<CelebrationIcon />}
        />
      </VerticalTimeline>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        maxWidth="lg"
        BackdropProps={{
          timeout: 300
        }}
      >
        <Fade in={open}>
          <iframe width="600px" height="500px" src="/assets/TermsAndConditions.pdf" />
        </Fade>
      </Dialog>
    </>
  );
};

export default HomeMentoring;
